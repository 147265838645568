<template>
  <main class="main" role="main">
    <div class="page">
      <div class="inner">
        <div class="page_con">
          <div class="page_tab">
            <div class="btn_wrap">
              <button
                :class="{ on: buttonIndex === 0 }"
                @click="() => (buttonIndex = 0)"
              >
                구독</button
              ><!--'on'-->
              <button
                :class="{ on: buttonIndex === 1 }"
                @click="() => (buttonIndex = 1)"
              >
                마켓
              </button>
            </div>
          </div>
          <h1 class="con_ttl">
            <span>알림</span>
            <button class="btn_setting" @click="fnWrite"></button>
          </h1>
          <div class="lump" v-if="!$Util.isEmpty(detail)">
            <div class="alarm_setting">
              <dl>
                <dt>카테고리</dt>
                <dd>{{ detail.categoryNm }}</dd>
              </dl>
              <dl>
                <dt>이용기간</dt>
                <dd>{{ detail.usePeriod }}일</dd>
              </dl>
              <dl>
                <dt>알림기한</dt>
                <dd>{{ $dayjs(detail.notiDt).format('YYYY.MM.DD') }}</dd>
              </dl>
              <dl>
                <dt>구매희망가</dt>
                <dd>
                  {{
                    `${$Util.formatNumber2(detail.minPrice, {
                      unit: ''
                    })} ~ ${$Util.formatNumber2(detail.maxPrice, { unit: '' })}`
                  }}
                </dd>
              </dl>
              <dl>
                <dt>검색어 설정</dt>
                <dd>{{ formatSearchWord(detail.searchWord) }}</dd>
              </dl>
              <!-- 20210809 주석처리 -->
              <!--              <dl>-->
              <!--                <dt>APP 알림 수신</dt>-->
              <!--                <dd>{{ detail.notiYn === 'Y' ? 'ON' : 'OFF' }}</dd>-->
              <!--              </dl>-->
            </div>
          </div>
          <div class="empty_wrap" v-else>
            <div class="empty">
              <div>
                현재 설정된 파티 알림이 없습니다.<br />
                파티 알림 설정을 통해<br />원하는 파티를 빠르고 쉽게 만나세요.
              </div>
            </div>
          </div>
          <div v-if="!$Util.isEmpty(detail)" class="bg">
            <h1 class="con_ttl bg">
              <span>파티 목록</span>
            </h1>
            <div class="party_list_wrap bg" v-if="!$Util.isEmpty(list)">
              <div class="list_top">
                <div class="left">
                  <button class="total">총 {{ totalCnt }}건</button>
                </div>
                <div class="right">
                  <span class="toggle_ttl">알림</span>
                  <label class="toggle_type01">
                    <input type="checkbox" />
                    <span class="circle"></span>
                  </label>
                </div>
              </div>
              <div class="party_list row_type">
                <!--'가로형: row_type'-->
                <ul class="party_list_ul">
                  <li
                    class="party_list_li"
                    v-for="(item, index) in list"
                    :key="index"
                    @click="fnView(item)"
                  >
                    <div class="party_tag">
                      <div class="left">
                        <div
                          class="pt_tag logo"
                          style="--pt-txt-color: #fff;"
                          :style="{
                            '--pt-bg-color': $ConstCode.getCategoryById(
                              item.categoryId
                            ).color
                          }"
                        >
                          {{ item.categoryNm }}
                        </div>
                        <div class="pt_tag normal nb">
                          {{
                            $Util.formatNumber2(item.recruitNum, { unit: '명' })
                          }}
                        </div>
                        <div class="right">
                          <div
                            class="pt_tag"
                            :class="{ new: item.isNew }"
                          ></div>
                        </div>
                      </div>
                    </div>
                    <div class="party_ttl">
                      {{ item.title }}
                    </div>
                    <div class="party_con">
                      <div class="left">
                        <!--                        <div class="party_info">-->
                        <!--                          <dl>-->
                        <!--                            <dt>파티장 :</dt>-->
                        <!--                            <dd>{{ item.name }}</dd>-->
                        <!--                          </dl>-->
                        <!--                          <dl>-->
                        <!--                            <dt>종료일 :</dt>-->
                        <!--                            <dd>-->
                        <!--                              {{ $dayjs(item.useDt).format('YYYY/MM/DD') }}-->
                        <!--                            </dd>-->
                        <!--                          </dl>-->
                        <!--                        </div>-->
                        <div class="party_info">
                          <dl>
                            <dt></dt>
                            <dd>
                              {{
                                `${$Util.formatNumber2(item.usePeriod, {
                                  unit: '일'
                                })}(일 평균 ${$Util.formatNumber2(
                                  item.dailyPrice,
                                  {
                                    unit: '원'
                                  }
                                )})`
                              }}
                            </dd>
                          </dl>
                        </div>
                      </div>
                      <div class="right">
                        <div class="party_price">
                          <span class="value">{{
                            $Util.formatNumber2(
                              Number(item.usePeriod) * Number(item.dailyPrice),
                              { unit: '' }
                            )
                          }}</span
                          ><!--띄어쓰기-->
                          <span class="unit">원</span>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div class="empty_wrap bg" v-else>
              <div class="empty">
                <div>
                  조건에 맞는 알림이 없습니다.
                </div>
              </div>
            </div>
            <observe-visible
              :key="initCnt"
              :page="page"
              :limit="limit"
              :totalCnt="totalCnt"
              @fetch="fnListFunction"
            />
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { componentState } from './index.js'
export default {
  name: 'my-page-party-noti-index',
  setup() {
    return { ...componentState() }
  }
}
</script>

<style scoped></style>
