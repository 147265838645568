import { reactive, toRefs, getCurrentInstance, computed, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'

const INIT_PAGE = 0
const INIT_LIMIT = 6
const INIT_TOTAL_CNT = 999

export const componentState = () => {
  const { proxy } = getCurrentInstance()
  const router = useRouter()
  const route = useRoute()
  const state = reactive({
    detail: {},
    page: INIT_PAGE,
    limit: INIT_LIMIT,
    totalCnt: INIT_TOTAL_CNT,
    list: [],
    initCnt: 0,
    buttonIndex: Number(route.query.index) || 0,
    params: computed({
      get: () => {
        return {
          page: state.page + 1
        }
      }
    })
  })
  watch(
    () => state.buttonIndex,
    () => {
      fnReset()
      state.initCnt++
    }
  )
  const fnWrite = async () => {
    await router.push({ path: '/my-page/party-noti/write' })
  }
  const fnDetail = async () => {
    const res = await proxy.$MyPageSvc.postMyPartyHopeDetail()
    if (res.resultCode === '0000') {
      state.detail = res.detail
    } else {
      alert(res.resultMsg)
    }
  }
  const formatSearchWord = text => {
    if (proxy.$Util.isEmpty(text)) return ''
    return text
      .split(',')
      .map(text => `#${text}`)
      .join(' ')
  }
  const init = async () => {
    await fnDetail()
  }
  const fnListFunction = async () => {
    const res = await proxy.$MyPageSvc.postMyHopeMatchPartyList(state.params)
    if (res.resultCode === '0000') {
      state.page++
      state.totalCnt = res.totalCnt
      state.lastPartyNo = res.lastPartyNo
      state.list = state.list.concat(
        res.list.map(item => {
          const temp = { ...item }
          if (state.page < 2) {
            if (state.lastPartyNo > item.partyNo) {
              temp.isNew = true
            } else {
              temp.isNew = false
            }
          } else {
            temp.isNew = false
          }
          return temp
        })
      )
    }
  }
  const fnSearch = () => {
    fnReset()
    state.initCnt++
  }
  const fnReset = () => {
    state.page = INIT_PAGE
    state.limit = INIT_LIMIT
    state.totalCnt = INIT_TOTAL_CNT
    state.list = []
  }
  const fnView = async item => {
    await router.push({ path: '/party/view', query: { partyNo: item.partyNo } })
  }
  init()
  return {
    ...toRefs(state),
    fnWrite,
    fnDetail,
    formatSearchWord,
    fnListFunction,
    fnSearch,
    fnView
  }
}
